

// components
import ArticleImages from "./ArticleImages";

// styles
import classes from './ArticleContent.module.css';
import {useHistory, useRouteMatch} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import AppContext from "../../AppContext";
import moment from "moment";

const ArticleContent = () => {
  const {blogs, articles, press} = useContext(AppContext)
  const history = useHistory();
  const { url } = useRouteMatch();
  const [article, setArticle] = useState(null)

  useEffect(() => {
    const category = url.split('/')[1];
    const articleUrl = url.split('/')[2];

    if (category === 'blog') {
      const obj = blogs.filter(el => el.blogUrl === articleUrl)[0]
      if (obj) {
        setArticle(obj)
      }
    }

    if (category === 'my-work-as-journalist') {
      const obj = articles.filter(el => el.blogUrl === articleUrl)[0]
      if (obj) {
        setArticle(obj)
      }
    }

    if (category === 'press-officer') {
      const obj = press.filter(el => el.blogUrl === articleUrl)[0]
      if (obj) {
        setArticle(obj)
      }
    }
  }, [blogs])

  console.log('article', article)

  return ( 
    <section className={classes.fm_article_wrapper}>
      <div className={classes.fm_article_container}>
        <div className={classes.fm_article_content}>

          <div className={classes.fm_article_left}>
            <img className={classes.fm_article_img} src={article?.coverImage} alt="press cover" />
            
            <div className={classes.fm_article_back_language}>
              <div className={classes.fm_back_arrow}>
                <img src="/img/back_arrow.svg" alt="back arrow" onClick={() => {history.goBack()}} /> <span>back</span>
              </div>
              <div className={classes.fm_back_language}>
                {article?.language}
              </div>
            </div>
           
            <div className={classes.fm_article_infos}>
              <div className={classes.fm_article_info}>
                <div className={classes.fm_article_title}>{article?.author}</div>
                <div className={classes.fm_article_state}>{article?.country}, <span>{article?.city}</span></div>
                <div className={classes.fm_article_date}>{moment(article?.dateCreated.toDate()).format("MMMM DD, YYYY")}</div>
              </div>

              <div className={classes.fm_article_share}>
                <div className={classes.fm_article_share_title}>Share this article</div>
                <div className={classes.fm_article_share_icons}>
                  <a href="https://facebook.com/" target="_blank" rel="noreferrer">
                    <img src="/img/home/facebook.svg" alt="fb logo" />
                  </a>
                  <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
                    <img src="/img/home/linkedin.svg" alt="in logo" />
                  </a>
                  <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                    <img src="/img/home/twitter.svg" alt="tw logo" />
                  </a>
                </div>
              </div>

            </div>
          </div>

          <div className={classes.fm_article_right}>
            <div className={classes.fm_article_right_state}>
              {article?.country}
            </div>

            <div className={classes.fm_article_right_title}>
              <h1>{article?.title}</h1>
            </div>

            <div className={classes.fm_article_right_date_read}>
              <div className={classes.fm_article_right_date}>
                {moment(article?.dateCreated.toDate()).format("MMMM DD, YYYY")}
              </div>

              <div className={classes.fm_article_right_read}>
                {article?.readEstimate}
              </div>
            </div>

            <div className={classes.fm_article_right_author}>
              {article?.author}
            </div>

            <div className={classes.fm_article_right_content} dangerouslySetInnerHTML={{ __html: article?.text }} />

            {/* carousel */}
            {article?.images.length > 0 && (
              <div className={classes.fm_carousel_holder}>
                <ArticleImages images={article.images} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
  
export default ArticleContent;
