
// components
import BlogArticle from './BlogArticle';

// styles
import classes from './Blogs.module.css';

const Blogs = ({blogs}) => {
  return ( 
    <section className="fm_wrapper">
      <div className="fm_container">
        <div className={classes.fm_blogs_holder}>
          {blogs.map(blog => <BlogArticle key={blog.id} blog={blog} />)}
        </div>
      </div>
    </section>
  )
}
  
export default Blogs;
