// components
import Heading from "../components/home/Heading";
import Bio from "../components/home/Bio";
import MyWork from "../components/home/MyWork";
import Press from "../components/home/Press";
import MyBlogs from "../components/home/MyBlogs";
import Stories from "../components/home/Stories";
import Footer from "../components/Footer";
import {useContext} from "react";
import AppContext from "../AppContext";

const Home = () => {
  const {blogs, articles, press} = useContext(AppContext)
  return (
    <main id="fm_homepage">
      <Heading />

      <Bio />

      <MyWork type='journalist' />
      <Press type='journalist' articles={articles} />

      <MyWork type='press-officer' />
      <Press type='press-officer' articles={press} />

      <MyBlogs  />
      <Stories blogs={blogs} />

      <Footer />
    </main>
  )
}

export default Home;
