import { Link } from 'react-router-dom';
import moment from "moment";

// styles
import classes from './TopBanner.module.css';

const TopBanner = ({data}) => {
  return ( 
    <section className={classes.fm_tnb_wrapper}>
      <div className="fm_container">
        <div className={classes.fm_tnb_content}>
          <img src={data?.coverImage} alt="blog cover" />

          <div className={classes.fm_img_dark_layer}>
            <div className={classes.fm_article_infos}>
              <div className={classes.fm_article_right_state}>{data?.country}</div>

              <div className={classes.fm_article_right_title}>
                <Link to="/article">
                  <h1>{data?.title}</h1>
                </Link>
              </div>
              <div className={classes.fm_article_right_date_read}>
                <div className={classes.fm_article_right_date}>
                  {moment(data?.dateCreated.toDate()).format("MMMM DD, YYYY")}
                </div>

                <div className={classes.fm_article_right_read}>
                  {data?.readEstimate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
  
export default TopBanner;