// components
import MyWorkAsJournalist from "../components/MyWorkAsJournalist";
import TopBanner from "../components/TopBanner";
import Filters from "../components/filters/Filters";
import Articles from "../components/my-work-as-journalist/Articles";
import Footer from "../components/Footer";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import moment from "moment";

const MyWorkAsJournalistPage = ({type}) => {
  const {articles, press} = useContext(AppContext);
  const [filtArticles, setFiltArticles] = useState([]);

  const pageUsedData = type === 'journalist' ? articles : press

  const onFilter = (data) => {
    let arr = [...pageUsedData];

    if (data.search) {
      const val = data.search.toLowerCase(); // for turning off case sensitive
      arr = filtArticles.filter((el) =>
        el.title.toLowerCase().includes(val) || el.text.toLowerCase().includes(val)
      );
    }

    if (data.country) {
      const val = data.country.text;
      arr = arr.filter((el) => el.country === val);
    }

    if (data.language) {
      const val = data.language.text;
      arr = arr.filter((el) => el.language === val);
    }

    if (data.year) {
      const val = data.year.text;
      arr = arr.filter((el) => moment(el.dateCreated.toDate()).format("YYYY") === val);
    }

    setFiltArticles(arr)
  }

  useEffect(() => {
    setFiltArticles(type === 'journalist' ? articles : press)
  }, [articles, press, type])

  return (
    <main id="fm_mwaj">
      <MyWorkAsJournalist type={type} hideMoreBtn />

      <TopBanner data={pageUsedData[0] || null} />

      <Filters onFilter={onFilter} />

      <Articles type={type} articles={filtArticles} />

      <Footer />
    </main>
  )
}

export default MyWorkAsJournalistPage;
