// style
import classes from './FilterInput.module.css';

const FilterInput = ({ onSearch }) => {
  return (
    <div className={classes.fm_input_holder}>
    <input type="text" onKeyDown={(e) => onSearch(e.target.value)} />
    <img src="/img/search_icon.svg" alt="search icon" />
    </div>
  )
}

export default FilterInput;