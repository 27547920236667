import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

import Button from "@mui/material/Button";
import AdminHomeContent from "../../components/admin/AdminHomeContent";

const AdminHome = () => {
  const history = useHistory();

  const handleLogout = () => {
    sessionStorage.removeItem('Auth Token');
    history.push('/admin/login')
  }

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')
    if (authToken) {
      history.push('/admin/home')
    }

    if (!authToken) {
      history.push('/admin/login')
    }
  }, [])

  return (
    <div>
      <AdminHomeContent />

      <Button onClick={handleLogout} variant="contained" color="error" style={{position: "absolute", top: "16px", right: "16px"}}>Log out</Button>
    </div>
  )
}

export default AdminHome