import React, { useState, useCallback } from 'react';

// carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// lightbox
import ImageViewer from 'react-simple-image-viewer';

// styles
import classes from './ArticleImages.module.css';

const ArticleImages = ({images}) => {
  
  // CAROUSEL 
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    }
  }; 

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className={classes.fm_custom_btn_group}>
        <button className={classes.fm_left_arrow} onClick={() => previous()}><img src="/img/car_arr_prev.svg" alt="arrow" /></button>
        <button className={classes.fm_right_arrow} onClick={() => next()}><img src="/img/car_arr_next.svg" alt="arrow" /></button>
      </div>
    );
  };

  // LIGHTBOX
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
      <div className={classes.fm_article_images}>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={responsive}
        dotListClass="custom-dot-list-style"
        itemClass={classes.fm_img_item}
        arrows={false}
        renderArrowsWhenDisabled={true}
        renderButtonGroupOutside
        customButtonGroup={<CustomButtonGroup />}
      >

        {images.map((src, index) => (
          <img
            src={ src }
            onClick={ () => openImageViewer(index) }
            key={ index }
            alt="img description"
          />
        ))}

      </Carousel>

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
    </div>
    )
}
  
export default ArticleImages;