import { Link } from 'react-router-dom';

// styles
import classes from './PressArticle.module.css';
import moment from "moment";

const PressArticle = ({article, type}) => {
  const linkUrl = type === 'journalist' ? 'my-work-as-journalist' : 'press-officer'

  return ( 
    <div className={classes.fm_article}>
      <div className={classes.fm_article_img}>
         <img src={article.coverImage} alt="article img" />
      </div>

      <div className={classes.fm_article_infos}>
        <div className={classes.fm_article_state}>
          {article.country}
        </div>

        <div className={classes.fm_article_title}>
          <h2><Link to={`/${linkUrl}/${article.blogUrl}`}>{article.title}</Link></h2>
        </div>

        <div className={classes.fm_article_short_descritpiton} dangerouslySetInnerHTML={{ __html: article?.text }} />

        <div className={classes.fm_article_date}>
          {moment(article.dateCreated.toDate()).format("MMMM DD, YYYY")}
        </div>

        <div className={classes.fm_article_read_time}>
          {article.readEstimate}
        </div>
      </div>
    </div>
  )
}
  
export default PressArticle;
