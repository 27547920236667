import { Link } from 'react-router-dom';

// styles
import classes from './Stories.module.css';
import moment from "moment";


const Stories = ({blogs}) => {
  return ( 
    <div className="fm_wrapper">
      <div className={classes.fm_mb_wrapper}>
        <div className="fm_container">      
          <div className={classes.fm_stories_content}>
            <div className={classes.fm_stories_articles}>
              <div className={classes.fm_stories_items}>

                {blogs.map(blog => (
                  <div key={blog.blogUrl} className={classes.fm_stories_item}>
                    <div className={classes.fm_stories_item_image_holder}>
                      <div className={classes.fm_stories_item_image}>
                        <img src={blog.coverImage} alt="stories cover" />
                      </div>
                    </div>

                    <div className={classes.fm_stories_item_infos}>
                      <div className={classes.fm_stories_item_state}>
                        {blog.country}
                      </div>

                      <div className={classes.fm_stories_item_title}>
                        <Link to={`/blog/${blog.blogUrl}`}>{blog.title}</Link>
                      </div>

                      <div>
                        <div className={classes.fm_stories_item_date}>
                          {moment(blog.dateCreated.toDate()).format("MMMM DD, YYYY")}
                        </div>

                        <div className={classes.fm_stories_item_read_time}>
                          {blog.readEstimate}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.fm_see_all_articles}> 
              <h2>CLICK HERE TO SEE MORE STORIES</h2>
              <img src="/img/home/dark_underline_big.svg" alt="underline" />
              <Link to="/blog">
                more articles
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
  
export default Stories;