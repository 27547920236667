import React, {useState} from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './LoginForm.module.css'

const ModalForm = ({type, data}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const handleAction = () => {
    console.log('submit')
  }

  return (
    <div className={styles.formWrapper}>
      <div className="heading-container">
        <h3 className="mb-3">
          Login Form
        </h3>
      </div>

      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="name"
          label="Enter the Title"
          variant="outlined"
          error={!name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>

      {loading ? (
        <LoadingButton loading variant="outlined">
          Submit
        </LoadingButton>
      ) : (
        <Button onClick={handleAction} variant="contained" l>Login</Button>
      )}

      <ToastContainer />
    </div>
  )

}

export default ModalForm