// components
import MyWorkAsJournalist from '../MyWorkAsJournalist';

// styles
import classes from './MyWork.module.css';


const MyWork = ({type}) => {
  return ( 
    <div className="fm_wrapper">
      <div className={classes.fm_container_mywork}>      
        <MyWorkAsJournalist type={type} />
      </div>
    </div>
  )
}
  
export default MyWork;
