import {useEffect, useState} from 'react';

// components
import SelectCountry from './select/SelectCountry';
import SelectYear from './select/SelectYear';
import SelectLanguage from './select/SelectLanguage';
import FilterInput from './select/FilterInput';

// styles
import classes from './Filters.module.css';

const Filters = ({onFilter}) => {
  // small reso show hide filters
  const [openFilters, setOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    country: null, year: null, language: null, search: ''
  });
  const [filtersArr, setFiltersArr] = useState([]);

  const openFiltersSmall = () => {
    setOpenFilters(!openFilters)
  }

  const onSelectFilter = (e, type) => {
    const filterObj = {...activeFilters};

    filterObj[type] = e;

    const arr = []


    Object.keys(filterObj).map((key, index) => {
      if (filterObj[key]) {
        arr.push(filterObj[key])
      }

      return key;
    });

    setFiltersArr(arr);
    setActiveFilters(filterObj)
  }

  const onRemoveFilter = (e) => {
    const filterObj = {...activeFilters};
    const arr = []


    Object.keys(filterObj).map((key, index) => {
      const data = filterObj[key]
      if (data && data.text === e.text) {
        filterObj[key] = null;
      }

      return key;
    });

    Object.keys(filterObj).map((key, index) => {
      if (filterObj[key]) {
        arr.push(filterObj[key])
      }

      return key
    });

    setFiltersArr(arr);
    setActiveFilters(filterObj)
  }

  const onSearch = (val) => {
    const filterObj = {...activeFilters};

    filterObj['search'] = val;

    setActiveFilters(filterObj)
  }

  useEffect(() => {
    onFilter(activeFilters)
  }, [activeFilters])

  return ( 
    <section className={classes.fm_filters_wrapper}>
      <div className="fm_container">
        <div className={classes.fm_filters_content}>
          <div className={classes.fm_open_filters_small} onClick={openFiltersSmall}>
            {!openFilters ? "Open Filters" : "Save and Close Filters" }
          </div>

          <div className={`${classes.fm_filters_holder} ${openFilters ? classes.fm_filters_open : ''}`}>
            <div className={classes.fm_filters_top}>
              <div className={classes.fm_filters_left}>
                <SelectCountry onSelect={onSelectFilter} selectedOption={activeFilters.country} />
                <SelectYear onSelect={onSelectFilter} selectedOption={activeFilters.year} />
                <SelectLanguage onSelect={onSelectFilter} selectedOption={activeFilters.language} />
              </div>

              <div className={classes.fm_filters_right}>
                <FilterInput onSearch={onSearch} />
              </div>
            </div>

            <div className={classes.fm_filters_bot}>
              {filtersArr.map(el =>
                <div key={el.value} className={classes.fm_filtrers_selected_item}>
                  {el.text}
                  <img src="/img/remove_filter.svg" alt="remove" onClick={() => onRemoveFilter(el)} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
  
export default Filters;
