// components
import ArticleContent from "../components/article/ArticleContent";

const Article = ({article}) => {
  return (
    <main id="fm_article">
      <ArticleContent article={article} />
    </main>
  )
}

export default Article;