import { Link } from "react-router-dom";

// styles
import classes from './MyWorkAsJournalist.module.css';


const MyWorkAsJournalist = ({type, hideMoreBtn = false}) => {
  return ( 
    <div className={classes.fm_mwaj_wrapper}>
      <div className="fm_container">      
        <div className={classes.fm_mwaj_content}>          
          <div className={classes.fm_mwaj_title}>
            <div className={classes.fm_mwaj_title_l}>MY WORK AS</div>
            <div className={classes.fm_mwaj_title_r}>
              <div className={classes.fm_mwaj_title_r_top}>{type === 'journalist' ? '#A JOURNALIST' : '#PRESS OFFICER'}</div>
            </div>
          </div>

          <div className={classes.fm_mwaj_text_content}>
            <div className={classes.fm_mwaj_text_btn}>
              {type === 'journalist' ? (<p>
                Most of my stories as a journalist remain made of ink on paper and won’t find their way to these pages. This includes most of my frontline work, such as Hezbollah-Israel war of 2006 and “Cast Lead” in Gaza (2007-8). In those days and earlier, writers would file stories by a set deadline, sometimes dictating texts on the phone (not smart ones), standing on a roof to use a massive and hyper expensive satellite phone, while professionals behind the camera would take pictures.
                <br />
                <br />
                Here you will find some more recent examples of my writing and pictures, as at some point we had to become “multimedia”.  I am nostalgic of the old era, and I feel privileged of having lived it for a while. Most relevantly, I am forever grateful to older colleagues I met in the field for all I have learned from them. Some are no longer with us and bear a special place in my heart.
              </p>) : (<p>No matter what subject you approach as a press officer, you can do it by telling a story. It is the only way your audience will be listening to you and recall your key messages.
                <br/>
                <br/>
                I have been a Press Officer/Spokesperson for the European Union in Crisis Management Missions since 2013. This was not my first experience in this role, as I also previously worked in UN Peacekeeping Operations (Burundi 2004 and 2005). In these pages some examples of my story telling, the most effective way to get your message though.</p>)}

              {!hideMoreBtn &&
              <div className={classes.fm_mwaj_btn}>
                <p>CLICK HERE TO SEE ALL ARTICLES</p>
                <img src="/img/home/my_work_arrow_right.svg" alt="right arrow" />
                <Link to={type === 'journalist' ? "/my-work-as-journalist" : "/press-officer"}>
                    more articles
                </Link>
              </div>
              }
            </div>

            <div className={classes.fm_mwaj_logo_holder}>
              <Link to="/">
                <img src="/img/logo_black.svg" alt="logo dark" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default MyWorkAsJournalist;
