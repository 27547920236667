import React, {useContext} from 'react';
import Select, { components } from "react-select";

// components

// style
import classes from './SelectCountry.module.css';
import AppContext from "../../../AppContext";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
       <img src="/img/select_arrow.svg" alt="arrow down" />
    </components.DropdownIndicator>
  );
};


const SelectCountry = ({onSelect, selectedOption}) => {
  const { countries } = useContext(AppContext);
 
  // handle onChange event of the dropdown
  const handleChange = e => {
    onSelect(e, 'country')
  }

  // SELECT STYLES
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: 'transparent',
      color: '#E6E6E6',
      padding: '9px 15px 9px 0',
      fontSize: 16,
      fontFamily: 'Palanquin',
      fontWeight: 400,
      cursor: 'pointer',
    }),
    control: (base, state) => ({
      border: '4px solid #FFD51C',
      borderRadius: '15px',
      display: 'flex',
      cursor: 'pointer',
      background: '#333333',
    }),
    indicatorSeparator: () => {},
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform .3s ease',
      transform: state.selectProps.menuIsOpen  ? "rotate(180deg)" : "rotate(0deg)"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '12px 16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';      
  
      return { 
        ...provided, 
        opacity, 
        transition,
        width: '100%',
        color: '#E6E6E6',
        fontSize: 16,
        fontFamily: 'Palanquin',
        fontWeight: 400,
      };
    },
    container: (provided, state) => ({
      maxWidth: '258px',
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      borderRadius: '15px',
      border: '4px solid #FFD51C',
      boxShadow: 'none',
      background: '#333333',
      padding: '12px 16px',
    }),
    menuList: (base) => ({
      ...base,
      height: 'auto',
      maxHeight: 'auto',
      minHeight: "auto",
      padding: 0,
      color: '#E6E6E6',
    }),
  }

  return (
    <>
      <div className={classes.fm_select_holder}>
        <Select
          styles={customStyles}
          placeholder="select country"
          value={selectedOption}
          defaultValue={countries}
          options={countries}
          onChange={handleChange}
          isSearchable={false}
          blurInputOnSelect
          closeMenuOnSelect
          components={{ DropdownIndicator }}
          // menuIsOpen 
          getOptionLabel={e => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {e.icon}
              <span>{e.text}</span>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default SelectCountry;