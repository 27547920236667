
// components
import PressArticle from './PressArticle';

// styles
import classes from './Articles.module.css';

const Articles = ({articles, type}) => {
  return ( 
    <section className="fm_wrapper">
      <div className="fm_container">
        <div className={classes.fm_articles_holder}>
          {articles.map(article => <PressArticle key={article.id} article={article} type={type} />)}
        </div>
      </div>
    </section>
  )
}
  
export default Articles;
