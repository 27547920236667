import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

// pages
import Home from './pages/Home';
import Article from './pages/Article';
import MyWorkAsJournalistPage from './pages/MyWorkAsJournalistPage';
import Blog from './pages/Blog';

// components
import Navigation from './components/Navigation';
import {collection, getDocs, query, orderBy} from "firebase/firestore";
import {db} from "./firebaseConfig";
import AppContext from "./AppContext";
import AdminHome from "./pages/Admin/AdminHome";
import AdminLogin from "./pages/Admin/AdminLogin";
import {getLocalStorage, setLocalStorage} from "./utility/localStorageAction";


function App() {
  const [press, setPress] = useState([])
  const [articles, setArticles] = useState([])
  const [blogs, setBlogs] = useState([])
  const [countries, setCountries] = useState([])
  const [langs, setLangs] = useState([])

  const getBlogs = async () => {
    const blogRef = collection(db, 'blog');
    const q = query(blogRef, orderBy("dateCreated", "desc"));
    const blogArr = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      blogArr.push({id: doc.id, ...doc.data()})
    });
    setBlogs(blogArr);

    const lsBlogArr = {
      array: blogArr,
      date: new Date()
    }

    setLocalStorage('blog', lsBlogArr)
  }

  const getArticles = async () => {
    const articleRef = collection(db, 'article');
    const q = query(articleRef, orderBy("dateCreated", "desc"));
    const articleArr = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      articleArr.push({id: doc.id, ...doc.data()})
    });
    setArticles(articleArr);

    const lsArticleArr = {
      array: articleArr,
      date: new Date()
    }

    setLocalStorage('article', lsArticleArr, )
  }

  const getPress = async () => {
    const articleRef = collection(db, 'press');
    const q = query(articleRef, orderBy("dateCreated", "desc"));
    const pressArr = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pressArr.push({id: doc.id, ...doc.data()})
    });
    setPress(pressArr);

    const lsPressArr = {
      array: pressArr,
      date: new Date()
    }

    setLocalStorage('press', lsPressArr)
  }

  const getCountries = async () => {
    const countryRef = collection(db, 'country');
    const countryArr = []
    const querySnapshot = await getDocs(countryRef);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      countryArr.push({id: doc.id, ...doc.data()})
    });
    setCountries(countryArr);

    const lsLCountryArr = {
      array: countryArr,
      date: new Date()
    }

    setLocalStorage('country', lsLCountryArr)
  }

  const getLanguages = async () => {
    const langRef = collection(db, 'language');
    const langArr = []
    const querySnapshot = await getDocs(langRef);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      langArr.push({id: doc.id, ...doc.data()})
    });
    setLangs(langArr);

    const lsLAngArr = {
      array: langArr,
      date: new Date()
    }

    setLocalStorage('lang', lsLAngArr)
  }

  // 100vh calc
  useEffect(() => {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      //usage 100vh in .css
      // height: calc(var(--vh, 1vh) * 100);
    })

    const blogCookie = getLocalStorage('blog')
    if (true) {
      getBlogs()
    } else {
      console.log(blogCookie)
    }


    const articleCookie = getLocalStorage('article')
    if (true) {
      getArticles()
    } else {
      console.log(articleCookie)
    }

    const pressCookie = getLocalStorage('press')
    if (true) {
      getPress()
    } else {
      console.log(pressCookie)
    }

    const countryCookie = getLocalStorage('country')
    if (true) {
      getCountries()
    } else {
      setCountries(countryCookie)
    }

    const langCookie = getLocalStorage('lang')
    if (true) {
      getLanguages()
    } else {
      setLangs(langCookie)
    }
  }, []);

  return (
    <AppContext.Provider value={{blogs, articles, press, countries, langs}}>
      <main>
        <Switch>
          <Route exact path="/home">
            <Navigation />
            <Home />
          </Route>

          {/*journalist*/}
          <Route path="/my-work-as-journalist" exact>
            <Navigation />
            <MyWorkAsJournalistPage type='journalist' />
          </Route>

          <Route path="/my-work-as-journalist/:id">
            <Navigation />
            <Article />
          </Route>

          {/*press-officer*/}
          <Route path="/press-officer" exact>
            <Navigation />
            <MyWorkAsJournalistPage type='press-officer' />
          </Route>

          <Route path="/press-officer/:id">
            <Navigation />
            <Article />
          </Route>

          {/*Blog*/}
          <Route path="/blog" exact>
            <Navigation />
            <Blog />
          </Route>

          <Route path="/blog/:id">
            <Navigation />
            <Article />
          </Route>

          {/*ADMIN PAGES*/}
          <Route exact path="/admin/home">
            <AdminHome />
          </Route>

          <Route exact path="/admin/login">
            <AdminLogin />
          </Route>

          <Redirect from="/" to="/home" />
        </Switch>
      </main>
    </AppContext.Provider>
  );
}

export default App;
