// components
import MyBlog from "../components/MyBlog";
import TopBanner from "../components/TopBanner";
import Filters from "../components/filters/Filters";
import Blogs from "../components/blog/Blogs";
import Footer from "../components/Footer";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import moment from "moment";

const Blog = () => {
  const {blogs} = useContext(AppContext);
  const [filtBlogs, setFiltBlogs] = useState([]);

  const onFilter = (data) => {
    let arr = [...blogs];

    if (data.search) {
        const val = data.search.toLowerCase(); // for turning off case sensitive
        arr = filtBlogs.filter((el) =>
            el.title.toLowerCase().includes(val) || el.text.toLowerCase().includes(val)
        );
    }

    if (data.country) {
      const val = data.country.text;
      arr = arr.filter((el) => el.country === val);
    }

    if (data.language) {
      const val = data.language.text;
      arr = arr.filter((el) => el.language === val);
    }

    if (data.year) {
      const val = data.year.text;
      arr = arr.filter((el) => moment(el.dateCreated.toDate()).format("YYYY") === val);
    }

    setFiltBlogs(arr)
  }

  useEffect(() => {
    setFiltBlogs(blogs)
  }, [blogs])

  return (
    <main id="fm_blog">
      <MyBlog />

      <TopBanner data={blogs[0] || null} />

      <Filters onFilter={onFilter} />

      <Blogs blogs={filtBlogs} />

      <Footer />
    </main>
  )
}

export default Blog;
