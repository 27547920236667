import { Link, useRouteMatch   } from "react-router-dom"; 

// styles
import classes from './MyBlog.module.css';


const MyBlog = () => {
  return ( 
    <div className={classes.fm_mb_wrapper}>
      <div className="fm_container">      
        <div className={classes.fm_mb_content}>          
          <div className={classes.fm_mb_text_content}>
            <div className={classes.fm_mb_text_btn}>
              <div className={classes.fm_mb_title}>
                BLOG
              </div>

              <p>A blog a story that get regularly updated on a website. In this respect this blog is not typical, so the update has been so far random. This might change into a regular feed, so check it out, especially if you like the stories posted as example.</p>

              {!useRouteMatch("/blog") &&
              <div className={classes.fm_mb_btn}>
                <div className={classes.fm_text_underline}>
                  <p>CLICK HERE TO SEE ALL ARTICLES</p>
                  <img src="/img/home/dark_underline.svg" alt="underline" />
                </div>
                <img src="/img/home/my_work_arrow_right.svg" alt="right arrow" />
                <Link to="/blog">
                    more articles
                </Link>
              </div>
              }
            </div>
            <div className={classes.fm_mb_logo_holder}>
              <img src="/img/stories_arrow.svg" alt="stories arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default MyBlog;
