import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './LoginForm.module.css'

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let history = useHistory();

  const handleAction = () => {
    if (!email) {
      return
    }

    if (!password) {
      return;
    }

    const authentication = getAuth();
    setLoading(true);
    signInWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        setLoading(false);
        history.push('/admin/home')
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === 'auth/wrong-password') {
          toast.error('Please check the Password');
        } else if (error.code === 'auth/user-not-found') {
          toast.error('Please check the Email');
        } else {
          toast.error('Oooops, something went wrong!');
        }
      })
  }

  return (
    <div className={styles.formWrapper}>
      <div className="heading-container">
        <h3 className="mb-3">
          Login Form
        </h3>
      </div>

      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="email"
          label="Enter the Email"
          variant="outlined"
          error={!email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="password"
          label="Enter the Password"
          variant="outlined"
          type="password"
          error={!password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>

      {loading ? (
        <LoadingButton loading variant="outlined">
          Submit
        </LoadingButton>
      ) : (
        <Button onClick={handleAction} variant="contained" l>Login</Button>
      )}

      <ToastContainer />
    </div>
  )

}

export default LoginForm