import { Link } from 'react-router-dom';

// styles
import classes from './BlogArticle.module.css';
import moment from "moment";

const BlogArticle = ({blog}) => {
  return ( 
    <div className={classes.fm_article}>
      <div className={classes.fm_article_img}>
         <img src={blog.coverImage} alt="article img" />
      </div>

      <div className={classes.fm_article_infos}>
        <div className={classes.fm_article_state}>
          {blog.country}
        </div>

        <div className={classes.fm_article_title}>
          <h2><Link to={`/blog/${blog.blogUrl}`}>{blog.title}</Link></h2>
        </div>

        <div className={classes.fm_article_date}>
          {moment(blog.dateCreated.toDate()).format("MMMM DD, YYYY")}
        </div>

        <div className={classes.fm_article_read_time}>
          {blog.readEstimate}
        </div>

        <div className={classes.fm_article_text} dangerouslySetInnerHTML={{ __html: blog?.text }} />
      </div>
    </div>
  )
}
  
export default BlogArticle;
