import { Link } from 'react-router-dom';

// styles
import classes from './Press.module.css';
import moment from "moment";


const Press = ({type, articles}) => {
  const mainArticle = articles[0];
  const linkUrl = type === 'journalist' ? 'my-work-as-journalist' : 'press-officer'

  return ( 
    <div className="fm_wrapper">
      <div className="fm_container">      
        <div className={classes.fm_press_content}>
          <div className={classes.fm_press_articles}>
            <div className={classes.fm_press_single_item_holder}>

              {/* single big press item */}
              <div className={classes.fm_single_item}>
                <img src={mainArticle?.coverImage} alt="press cover" />

                <div className={classes.fm_singl_dark_layer} />

                <div className={classes.fm_press_single_infos}>
                  <div className={classes.fm_press_single_state}>
                    {mainArticle?.country}
                  </div>

                  <div className={classes.fm_press_single_title}>
                    <Link to={`/${linkUrl}/${mainArticle?.blogUrl}`}>{mainArticle?.title}</Link>
                  </div>

                  <div className={classes.fm_press_single_date}>
                    {moment(mainArticle?.dateCreated.toDate()).format("MMMM DD, YYYY")}
                  </div>

                  <div className={classes.fm_press_single_read_time}>
                    {mainArticle?.readEstimate}
                  </div>
                </div>
              </div>

            </div>

            <div className={classes.fm_press_items}>
              {articles.map((article, index) => (
                <>
                  {index > 0 && (
                    <div key={article.blogUrl} className={classes.fm_press_item}>
                      <div className={classes.fm_press_item_image_holder}>
                        <div className={classes.fm_press_item_image}>
                          <img src={article?.coverImage} alt="press cover" />
                        </div>
                      </div>
                      <div className={classes.fm_press_item_infos}>
                        <div className={classes.fm_press_item_state}>
                          {article.country}
                        </div>

                        <div className={classes.fm_press_item_title}>
                          <Link to={`/${linkUrl}/${article.blogUrl}`}>{article.title}</Link>
                        </div>

                        <div>
                          <div className={classes.fm_press_item_date}>
                            {moment(article.dateCreated.toDate()).format("MMMM DD, YYYY")}
                          </div>

                          <div className={classes.fm_press_item_read_time}>
                            {article.readEstimate}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>

          <div className={classes.fm_see_all_articles}> 
            <h2>CLICK HERE TO SEE ALL ARTICLES</h2>
            <img src="/img/home/light_underline.svg" alt="underline" />
            <Link to={`/${linkUrl}`}>
              more articles
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}
  
export default Press;
